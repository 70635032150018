var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.loaded
        ? _c(
            "div",
            { staticClass: "web-hosting-service-info-slide-container" },
            [
              _vm.showWebsite
                ? [
                    _vm.hostingError === null
                      ? _c("ct-centered-spinner")
                      : _vm.hostingError
                      ? _c(
                          "div",
                          { staticClass: "text-left" },
                          [
                            _vm._v(
                              "\n        Your website " +
                                _vm._s(_vm.domain.domain_name) +
                                " isn’t ready to be worked on yet, but is in process. Please check back on your website via the\n        "
                            ),
                            _c(
                              "router-link",
                              { attrs: { to: { name: "websites" } } },
                              [_vm._v("Websites & Email")]
                            ),
                            _vm._v(
                              " tab for updates. If you have questions or concerns, give us a call:\n        "
                            ),
                            _c("span", {
                              directives: [
                                {
                                  name: "phone-number",
                                  rawName: "v-phone-number",
                                  value: _vm.phone(),
                                  expression: "phone()",
                                },
                              ],
                              attrs: { title: "Telephone" },
                            }),
                            _vm._v(".\n      "),
                          ],
                          1
                        )
                      : _c("iframe", {
                          attrs: {
                            src: _vm.domainUrl,
                            width: "100%",
                            height: "400px",
                          },
                        }),
                  ]
                : _c("div", { staticClass: "wordpress-default-image-div" }, [
                    _c("picture", [
                      _c("source", {
                        attrs: {
                          srcset: require("@images/wordpress.webp"),
                          type: "image/webp",
                        },
                      }),
                      _c("img", {
                        attrs: {
                          src: require("@images/wordpress.png"),
                          alt: "Wordpress",
                        },
                      }),
                    ]),
                  ]),
              _vm.showViewWebsiteModalButton
                ? _c(
                    "div",
                    [
                      _vm.hostingError
                        ? _c("primary-button", {
                            staticClass: "mt-3",
                            attrs: {
                              "button-text": "Websites",
                              "aria-label": "view button",
                            },
                            on: {
                              onclick: function ($event) {
                                return _vm.$router.push({ name: "websites" })
                              },
                            },
                          })
                        : _c("primary-button", {
                            staticClass: "mt-3",
                            attrs: {
                              "button-text": "View/Edit My Website",
                              "aria-label": "view button",
                            },
                            on: {
                              onclick: function ($event) {
                                return _vm.showSiteBuilderModal()
                              },
                            },
                          }),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            2
          )
        : _c("div", [
            _c(
              "div",
              { staticClass: "d-flex justify-content-center mt-2 mb-2" },
              [_c("ct-centered-spinner")],
              1
            ),
          ]),
      _c("hosting-login-info-modal", {
        attrs: { "hosting-info": _vm.hostingInfo },
        on: { close: _vm.closeHostingInfoModal },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <div>
    <div v-if="loaded" class="web-hosting-service-info-slide-container">
      <template v-if="showWebsite">
        <ct-centered-spinner v-if="hostingError === null" />
        <div v-else-if="hostingError" class="text-left">
          Your website {{domain.domain_name}} isn’t ready to be worked on yet, but is in process. Please check back on your website via the
          <router-link :to="{name: 'websites'}">Websites & Email</router-link> tab for updates. If you have questions or concerns, give us a call:
          <span v-phone-number="phone()" title="Telephone" />.
        </div>
        <iframe
          v-else
          :src="domainUrl"
          width="100%"
          height="400px"
        />
      </template>

      <div v-else class="wordpress-default-image-div">
        <picture>
          <source
            :srcset="require('@images/wordpress.webp')"
            type="image/webp"
          >
          <img
            :src="require('@images/wordpress.png')"
            alt="Wordpress"
          >
        </picture>
      </div>
      <div v-if="showViewWebsiteModalButton">
        <primary-button
          v-if="hostingError"
          class="mt-3"
          button-text="Websites"
          aria-label="view button"
          @onclick="$router.push({name: 'websites'})"
        />
        <primary-button
          v-else
          class="mt-3"
          button-text="View/Edit My Website"
          aria-label="view button"
          @onclick="showSiteBuilderModal()"
        />
      </div>
    </div>

    <div v-else>
      <div class="d-flex justify-content-center mt-2 mb-2">
        <ct-centered-spinner />
      </div>
    </div>

    <hosting-login-info-modal
      :hosting-info="hostingInfo"
      @close="closeHostingInfoModal"
    />
  </div>
</template>

<script>

import { mapActions, mapGetters } from 'vuex'
import { phoneNumber } from '@/directives/directives'

export default {
  name: 'WebHostingServiceInfo',
  components: {
    CtCenteredSpinner: () => import('@/components/shared/CtCenteredSpinner'),
    PrimaryButton:     () => import('@/components/StagelineV2/shared/PrimaryButton'),
    HostingLoginInfoModal: () => import('@/components/Services/Hosting/HostingLoginInfoModal'),
  },
  directives: { phoneNumber },
  data() {
    return {
      loaded: false,
      hostingError: null,
      website: this.$store.getters['website/website'],
    }
  },
  computed: {
    ...mapGetters('domains', [
      'domain',
      'webHosting',
      'domainHostingInfo',
    ]),
    domainUrl() {
      return this.domain?.domain_name ?
        `https://www.${this.domain.domain_name}` :
        ''
    },
    hostingInfo() {
      return this.domainHostingInfo || this.webHosting
    },
    hostingUrl() {
      return this.hostingInfo?.sitebuilder_url || ''
    },
    showViewWebsiteModalButton() {
      return this.hostingUrl
    },
    showWebsite() {
      return this.domainUrl && this.hostingUrl
    },
  },
  async mounted() {
    await this.getHostingStatus()
    this.loaded = true
  },
  methods: {
    ...mapActions('domains', [
      'fetchHostingStatus',
    ]),
    async getHostingStatus() {
      const resp = await this.fetchHostingStatus(this.domain?.id)
      const hostingStatus = resp.data.hosting_status

      this.hostingError = hostingStatus >= 400 || hostingStatus < 200
    },
    showSiteBuilderModal(item) {
      this.selectedDomain = item
      this.$bvModal.show('hosting-login-info-modal')
    },
    closeHostingInfoModal() {
      this.$bvModal.hide('hosting-login-info-modal')
    },
    phone() {
      if (!this.website || !Array.isArray(this.website.phone_numbers)) {
        return null
      }

      return (this.website.phone_numbers.find((a) => a.kind === 'billing') || {}).phone_number
    },
  },
}
</script>
<style scoped lang="scss">
.web-hosting-service-info-slide-container {
  text-align: center;
  margin-top: 1em;
  margin-bottom: 100px;

  .website-image {
    width: 50vw;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
    margin: auto;
  }

  .wordpress-default-image-div {
    margin: auto;
  }
}
</style>
